module.exports = [
  {   
    "anchor": [25,25],
    "height": 26,
    "textColor": "#FFFFFF",
    "url": "png/ping.png",
    "width": 26
  },
  {   
    "height": 28,
    "textColor": "#FFFFFF",
    "url": "png/ping.png",
    "width": 28
  },
  {   
    "height": 33,
    "textColor": "#FFFFFF",
    "url": "png/ping.png",
    "width": 33
  },
  {   

    "height": 39,
    "textColor": "#FFFFFF",
    "url": "png/ping.png",
    "width": 39
  },
  {   
    "height": 45,
    "textColor": "#FFFFFF",
    "url": "png/ping.png",
    "width": 45
  }
];