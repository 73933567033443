/*
 *   Copyright 2018, Cordite Foundation.
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {Home} from 'containers/Pages/Home/Home'
import {Swagger} from 'containers/Pages/Swagger/Swagger';
import {Braid} from 'containers/Pages/Braid/Braid'

const PAGES = {
  home: Home,
  swagger: Swagger,
  braid: Braid
}

export const Page = (props) => {
  const Handler = PAGES[props.page]

  return <Handler {...props} />
}

Page.propTypes = {
  page: PropTypes.oneOf(Object.keys(PAGES)).isRequired
}
