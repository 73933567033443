head
  base(href="${location}/")
  meta(charset="utf-8")
  meta(name="viewport" content="width=device-width")
  title='NMS'
  link(rel="icon" href="png/favicon/favicon.ico" sizes="16x16 24x24 32x32 64x64" type="image/vnd.microsoft.icon")
  link(rel="stylesheet" href="app.css")
  link(href="https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700" rel="stylesheet")

  meta(name="title" content="Cordite Stats")
  meta(name="navigation-title" content="")
  meta(name="description" content="")
  meta(name="keywords" content="")

  meta(property="og:title" content="Cordite Stats")
  meta(property="og:type" content="website")
  meta(property="og:image" content="jpg/logo-watermark-og.jpg")
  meta(property="og:url" content="")
  meta(property="og:description" content="")

  meta(name="twitter:description" content="CorditeStats")
  meta(name="twitter:image:src" content="jpg/logo-watermark-og.jpg")
  meta(name="twitter:card" content="summary")
  meta(name="twitter:creator" content="@jchrisjones")
  meta(name="twitter:site" content="@we_are_cordite")
  meta(name="twitter:label1" value="Network Mapping Service")
  meta(name="twitter:data1" value="View nodes on the cordite network.")

  meta(name="theme-color" content="#0A2F64")
  meta(name="msapplication-navbutton-color" content="#0A2F64")
  meta(name="apple-mobile-web-app-capable" content="yes")
  meta(name="apple-mobile-web-app-status-bar-style" content="black")

  link(rel="apple-touch-icon" href="png/apple-touch/touch-icon-iphone.png")
  link(rel="apple-touch-icon" sizes="152x152" href="png/apple-touch/touch-icon-ipad.png")
  link(rel="apple-touch-icon" sizes="180x180" href="png/apple-touch/touch-icon-iphone-retina.png")
  link(rel="apple-touch-icon" sizes="167x167" href="png/apple-touch/touch-icon-ipad-retina.png")

  link(rel="icon" sizes="192x192" href="png/android/192x192_icon.png")
  link(rel="manifest" href="manifest.json")

  //- link(rel="canonical" href="")