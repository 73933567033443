module.exports = [
  [
    {
      "title": "dashboard",
      "icon": "fa-desktop"
    }
  ],
  [
    {
      "title": "Braid",
      "icon": "fa-random"
    }
  ]
];